import {useCallback, useEffect, useRef, useState} from "react";
import {inject, observer} from "mobx-react";
import {DropdownButton, Image, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {fetchChatCompletion} from "../../queries/autosuggest";
import LoadingAnimation from "../LoadingAnimation";
import logo from "../../img/official_logo.png";
import {copyToClipboard} from "../../lib/utils";
import DropdownFilter from "../DropdownFilter";
import SimpleSelect from "../controls/SimpleSelect";
import CopyToClipboardButton from "../CopyToClipboardButton";
import {renderMarkdown} from "../../markdown/markdown-factory";
import CollapsibleText from "../collapsible/CollapsibleText";
import {getConfig, getEnv} from "../../lib/configMgr";
import {useTypewriter} from "../../hooks/useTypewriter";
import {useSSEListener} from "../../hooks/useSSEListener";
import SearchContext from "../../lib/SearchContext";
import {escapeReferenceLinkText, fixNestedLists} from "../../markdown/interpolation";
import NumericInput from "../controls/NumericInput";
import Checkbox from "../controls/Checkbox";

const { ExternalRESTAPI } = getConfig();
const WELCOME = [{role: "system", content: "Welcome to the Plex LLM!\n\n"}];
const DEFAULT_MODEL = "anthropic.claude-3-5-sonnet-20240620-v1:0";


const sanitize = s => {
  // Make small branding tweaks to the LLM output
  return s.replace("Anthropic", "Plex").replace("Claude", "Plexy");
};


export const ChatModal = ({userStore, appStatusStore, isOpen = false, onHide = () => {}, auth = null,
                            searchParams = null, categories = {}, initialInput = ""}) => {

  const {isAdmin} = userStore;
  const {aiTemplates = {}, aiModels = {}} = appStatusStore;

  const SNAPSHOT_SIZES = [5, 10, 20, 25, 50, 100, 250, 500, 0];
  const SNAPSHOT_OPTIONS = SNAPSHOT_SIZES.map(x => `${x}`);

  const allCategories = Object.values(categories).filter(x => x.id !== "cpdset" && x.id !== "geneset");
  // Default some categories off
  const defaultOffCategories = new Set([
    /*"motif",
    "genome-position",
    "protein-family",
    "protein-domain",
    "superfamily",*/
  ]);

  const inputRef = useRef();
  const convoTailRef = useRef();
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [useTemperature, setUseTemperature] = useState(true);
  const [temperature, setTemperature] = useState(0.1);
  const [topP, setTopP] = useState(0.9);
  const [topK, setTopK] = useState(null);
  const [useEdges, setUseEdges] = useState(false);
  const [stream, setStream] = useState(getEnv() === "dev");
  const [model, setModel] = useState(DEFAULT_MODEL);
  const [convo, setConvo] = useState([...WELCOME]);
  const [adminResponse, setAdminResponse] = useState({});
  const convoStarted = convo.length > WELCOME.length;
  const [inputText, setInputText] = useState(initialInput || localStorage['llm-prompt'] || "");
  const [snapshotSize, setSnapshotSize] = useState(SNAPSHOT_OPTIONS[0]);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [streamURL, setStreamURL] = useState(null);
  const [streaming, setStreaming] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState("results-context")
  const [templates, setTemplates] = useState({...(aiTemplates || {})});
  const [showTemplates, setShowTemplates] = useState(false);
  const [showFull, setShowFull] = useState(false);
  const [typewriterText, startTyping] = useTypewriter();

  const hasSearchContext = searchParams?.searchIDs?.length;

  const handleStreamMessage = useCallback(event => {
    const msg = JSON.parse(event.data);
    //console.log(`SSE data received: ${event.data}`);
    if (msg.type === "message_start") {
      //{"type": "message_start","message": {"id": "msg_01GnRNG1psEzTMECgZr32tiv","content": [],"model": "claude-3-sonnet-28k-20240229","role": "assistant","stop_reason": null,"stop_sequence": null,"type": "message","usage": {"input_tokens": 11414,"output_tokens": 1}}}
      setConvo(convo => [...convo, {"role": "assistant", content: ""}]);
    }
    else if (msg.type === "content_block_start") {
      // {"content_block": {"text": "", "type": "text"}, "index": 0, "type": "content_block_start"}
      setConvo(convo => {
        const text = msg.content_block.text;
        const last = convo[convo.length - 1]
        return [...convo.slice(0, convo.length - 1), {...last, content: sanitize(last.content + text)}]
      });
    }
    else if (msg.type === "content_block_delta") {
      // {"delta": {"text": " that", "type": "text_delta"}, "index": 0, "type": "content_block_delta"}
      setConvo(convo => {
        const text = msg.delta.text;
        const last = convo[convo.length - 1];
        return [...convo.slice(0, convo.length - 1), {...last, content: sanitize(last.content + text)}];
      });
    }
    else if (msg.type === "content_block_stop") {
      // {"type": "content_block_stop", "index": 0}
      setConvo(convo => {
        const last = convo[convo.length - 1];
        // Perform any final cleanup here
        return [...convo.slice(0, convo.length - 1), {...last, content: last.content}];
      });
    }
    else if (msg.type === "message_delta") {
      //{"type": "message_delta","delta": {"stop_reason": "end_turn","stop_sequence": null},"usage": {"output_tokens": 62}}
    }
    else if (msg.type === "message_stop") {
      //{"type": "message_stop","amazon-bedrock-invocationMetrics": {"inputTokenCount": 11414,"outputTokenCount": 62,"invocationLatency": 5665,"firstByteLatency": 3598}}
      setStreaming(false);
      setStreamURL(null);
    }
    else if (msg.type === "ping") {
    }
    else if (msg.type === "error"){
      toast.error(`LLM error: ${msg.error}`);
      console.error(`Error streaming LLM response: ${msg.error}`);
      setStreaming(false);
      setStreamURL(null);
    }
  }, [streamURL]);

  const handleStreamError = useCallback((e) => {
    console.error(`Streaming error`, e);
    setStreaming(false);
    setStreamURL(null);
    setConvoError("Connection lost");
  }, [streamURL]);

  useSSEListener({url: streamURL, onData: handleStreamMessage, onError: handleStreamError});

  useEffect(() => {
    if (aiTemplates) {
      setTemplates({...aiTemplates});
    }
  }, [aiTemplates]);

  useEffect(() => {
    if (selectedCategories == null && allCategories.length) {
      setSelectedCategories(allCategories.filter(cat => !defaultOffCategories.has(cat.id)));
    }
  }, [allCategories.length]);

  useEffect(() => {
    if (!convoStarted && inputText !== initialInput) {
      setInputText(initialInput);
    }
  }, [convoStarted, initialInput]);

  const handleCategoryFilterApply = (categories) => {
    console.debug(`Apply categories ${JSON.stringify(categories.map(x => x.name))}`);
    setSelectedCategories(categories.length === allCategories.length ? null : categories);
  };
  const handleCategoryFilterClear = () => {
    console.debug("Using all categories");
    setSelectedCategories(null);
  }

  const handleModelChange = (e) => {
    setModel(e.target.value);
    setConvo([...WELCOME]);
  };
  const inputChanged = (e) => {
    const text = e.target.value || "";
    setInputText(text);
  }
  const clearMessages = () => {
    setConvo([...WELCOME]);
    setStreamURL(null);
  }
  const scrollAndFocus = () => {
    setTimeout(() => {
      if (isOpen && convoTailRef.current) convoTailRef.current.scrollIntoView({behavior: "smooth"});
      if (isOpen && inputRef.current) inputRef.current.focus();
    }, 0);
  };
  const handleResponse = response => {
    const {role, content = null, stream = null, error = null, ...adminContent} = response;
    setAdminResponse(adminContent);
    if (stream) {
      setStreaming(true);
      setStreamURL(`${ExternalRESTAPI.Stream}?channel=${stream}`);
    }
    else {
      setConvo([...convo, {role, content: ''}]);
      if (role === "system") {
        setConvo(convo => [...convo, {role, content, error}]);
      }
      else {
        startTyping(fixNestedLists(content));
      }
    }
  };

  useEffect(() => {
    convoTailRef.current?.scrollIntoView();
  }, [convo])

  useEffect(() => {
    const last = convo[convo.length - 1];
    if (last.role === "assistant") {
      setConvo([...convo.slice(0, convo.length - 1), {...last, content: typewriterText}]);
    }
  }, [typewriterText]);

  const setConvoError = (msg) => {
    setConvo(convo => {
      return [...convo, {"role": "system", "content": msg, "error": true}];
    });
  };

  const handleError = error => {
    console.error("LLM error", error);
    const msg = error.message
                ? error.message
                : error instanceof Response
                  ? `${error.status}: ${error.statusText}`
                  : JSON.stringify(error);
    setConvoError(msg);
  };
  const handleSendMessage = (msg = "") => {
    setInputText("");
    setWaitingForResponse(true);
    convo.push({role: "user", content: (msg || "").trim()});
    setConvo([...convo]);
    scrollAndFocus();
    const messages = convo.slice(WELCOME.length).map(({role, content}) => {
      return {role, content};
    });
    const editedTemplates = Object.entries(templates).reduce((result, [key, value]) => {
      if (value !== aiTemplates[key]) {
        result = {...(result || {}), key: value};
      }
      return result;
    }, null);
    const chatParams = {
      model, snapshotSize, stream,
      messages: messages.filter(m => m.role),
      top_k: topK,
      templates: editedTemplates,
      ...(useEdges ? {edges: true} : {}),
      ...(selectedCategories && selectedCategories?.length !== allCategories?.length
          ? {categories: selectedCategories.map(el => el.id)}
          : {}),
      // Use T or P, not both
      ...(useTemperature ? {temperature} : {top_p: topP}),
    };
    fetchChatCompletion(searchParams, chatParams, auth)
      .then((success, failure) => handleResponse(success))
      .catch(handleError)
      .finally(() => {
        scrollAndFocus();
        setWaitingForResponse(false);
      });
  };
  const handleClick = () => {
    handleSendMessage(inputText);
  };
  const inputKeyPressed = (e) => {
    e.stopPropagation();
    if (e.key === "Enter" && !(e.shiftKey || e.altKey)) {
      if (typeof(inputText) === "string") {
        handleSendMessage(inputText);
      }
    }
    else if (e.key === "Escape") {
      onHide();
    }
  };
  const onTemplateChange = (value) => {
    console.debug(`Template update ${value}`);
    const updated = {...templates};
    updated[selectedTemplate] = value;
    setTemplates(updated);
  };
  const toggleShowTemplates = () => {
    setShowTemplates(!showTemplates);
  };

  const mustRestart = "Re-start the conversation to change this setting";
  const waiting = "Waiting for LLM response";
  const topTitle = convoStarted ? mustRestart : waitingForResponse ? waiting : "Use the top N ranked items from each category";
  const filterTitle = convoStarted ? mustRestart : waitingForResponse ? waiting : "Select categories to use";

  const [context, setContext] = useState("");
  useEffect(() => {
    const promptContext = adminResponse.context || "";
    const searchResults = promptContext.indexOf("<search-results>") !== -1
                          ? promptContext.replace(/.*\n<search-results>\n(.*)\n<\/search-results>\n.*/s, "$1")
                          : "";
    setContext(showFull ? promptContext : searchResults);
  }, [showFull, adminResponse]);
  useEffect(() => {
    localStorage['llm-prompt'] = inputText;
  }, [inputText]);

  const searchURL = SearchContext.getAppSearchURL(searchParams);

  return (
    <Modal
      show={isOpen}
      onHide={onHide}
      className="top-level-modal"
      dialogClassName="ai-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Image className="logo" src={logo} />
          <span className="title">Large Language Model (LLM) Interface</span>
          {streaming && (<button className="blob glyphicon glyphicon-asterisk" />)}
          <button
            title="Copy to clipboard"
            disabled={!convoStarted}
            onClick={() => copyToClipboard(
              `Plex LLM (${model}), using top ${snapshotSize}\n`
              + (searchURL ? `Search URL: ${searchURL}\n\n` : "\n")
              + convo
                .slice(1)
                .reduce((result, el) => `${result}${el.role}: ${el.content}\n\n`, "")
                // PLEX-3328 formatting
                .replace("user: ", "User: ")
                .replace("assistant: ", "Plex-LLM: "))}
            className="flat-button glyphicon glyphicon-copy" />
          <button
            title="Delete conversation"
            disabled={waitingForResponse || !convoStarted}
            onClick={clearMessages}
            className={`flat-button glyphicon glyphicon-trash`} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body autoFocus={true} >
        {!hasSearchContext && (<div key="no-context" className="alert alert-info">{"There is no current search context"}</div>)}
        <div key="conversation" className="conversation" >
          {convo.map(({role = "user", content = "", error = null}, idx) => {
            if (role === "assistant") {
              return (
                <span key={idx} className={role}>
                  {renderMarkdown({
                                    markdown: escapeReferenceLinkText(content),
                                    overrides: {props: {forceBlock: false}},
                                    errorMessage: "Something went wrong parsing the LLM response"
                                  })}
                </span>
              );
            }
            if (role === "system") {
              return (
                <><span key={idx} className={`${role}${error ? " alert alert-error" : ""}`}>{content}</span><p key={`${idx}-cr`}/></>
              );
            }
            return (
              <span key={idx} className={role}><CollapsibleText text={content} visibleChars={500} minimumHidden={250} /><p/></span>
            );
          })}
          <span key="convo-tail" ref={convoTailRef}> </span>
        </div>
        {model.indexOf("gpt") !== -1 && (<div className="alert-warning" key="confidential-warning">
            <h6>WARNING: Not for confidential information. </h6>
            Queries submitted here, as well as the contents of the Plex search result, will be sent outside
            of Plex's secure environment.
        </div>)}
        <div className="user-input horizontal" key="user-input">
          {waitingForResponse || streaming
           ? (<LoadingAnimation />)
           : (<textarea
              className={"prompt"}
              ref={inputRef}
              disabled={waitingForResponse}
              placeholder={"Enter your prompt here"}
              value={inputText || ""}
              onChange={inputChanged}
              onKeyDown={inputKeyPressed}
            />)}
          <div key="controls" className="vertical">
            {isAdmin && (
              <>
                <select
                  className="admin"
                  onChange={handleModelChange}
                  defaultValue={model}
                  disabled={convoStarted || waitingForResponse}
                  title={"Choose the LLM model"}>
                  {Object.entries(aiModels).map(([modelID, modelName]) => (
                    <option key={modelID} value={modelID}>{`${modelName} (${modelID.replace(/^.*-v/, 'v')})`}</option>
                  ))}
                </select>
                <div className="admin model-settings">
                  <div className={"temp-p"} >
                    <SimpleSelect
                      disabled={convoStarted || waitingForResponse}
                      title={useTemperature
                             ? "Randomness (affects conservative vs. creative)"
                             : "Option probability threshold (affects diversity of outputs)"}
                      options={["T", "P"]}
                      onChange={v => setUseTemperature(v === "T")}
                    />
                    <NumericInput
                      disabled={convoStarted || waitingForResponse}
                      min={0} max={1} step={0.1} precision={2} value={useTemperature ? temperature : topP}
                      onChange={v => useTemperature ? setTemperature(v) : setTopP(v)}/>
                  </div>
                  <Checkbox
                    disabled={convoStarted || waitingForResponse}
                    label={"K"}
                    title={"Sample top K options (remove long tails of low-probability responses)"}
                    checked={topK != null}
                    onChange={v => setTopK(v ? 500 : null)}
                  >
                    <NumericInput
                      disabled={convoStarted || waitingForResponse || topK == null}
                      min={0} max={500} step={5} precision={0} value={topK}
                      onChange={v => setTopK(v)}/>
                  </Checkbox>
                </div>
                <Checkbox
                  disabled={waitingForResponse}
                  className="admin"
                  label="Stream Response"
                  title="Stream LLM responses"
                  checked={stream}
                  onChange={s => setStream(s)}
                />
                <Checkbox
                  disabled={!hasSearchContext || (hasSearchContext && convoStarted) || waitingForResponse}
                  className=""
                  label="Analyze Edges"
                  title="Whether to use connectivity information in analysis"
                  checked={useEdges}
                  onChange={s => setUseEdges(s)}
                />
              </>
            )}
            <div key="buttons" className="horizontal buttons">
              <button
                title="Send prompt"
                disabled={!inputText || waitingForResponse}
                onClick={handleClick}
                className={`flat-button glyphicon glyphicon-play`}/>
              <SimpleSelect
                title={topTitle}
                label={"Use top"}
                onChange={(value) => setSnapshotSize(value)}
                disabled={waitingForResponse || convoStarted}
                value={snapshotSize}
                options={SNAPSHOT_OPTIONS}
                getOptionLabel={(x) => (`${x}` === "0" ? "All" : `${x}`)}
              />
              <DropdownButton
                title={<span title={filterTitle} className="glyphicon glyphicon-filter"/>}
                className={`categories-selection${selectedCategories == null ? '' : ' active'}`}
                align="end"
                disabled={waitingForResponse || convoStarted}
              >
                <DropdownFilter
                  items={allCategories}
                  title={filterTitle}
                  currentFilterValue={selectedCategories}
                  getKey={x => x.id}
                  getLabel={x => x.name}
                  getTitle={x => x.description}
                  onFilterApply={handleCategoryFilterApply}
                  onFilterClear={handleCategoryFilterClear}
                  disabled={waitingForResponse || convoStarted}
                />
              </DropdownButton>
            </div>
          </div>
        </div>
        {isAdmin && (
          <div key="admin-options" className="admin" >
            <div className="ai-usage" key="ai-usage">
              {adminResponse.stop_reason && (<p key="stop-reason"><label className="stop-reason">Stop Reason</label>{JSON.stringify(adminResponse.stop_reason)}</p>)}
              {adminResponse.usage && (<p key="usage"><label className="usage">Usage</label>{JSON.stringify(adminResponse.usage)}</p>)}
              {convoStarted && (
                <label className="context" key="label" >
                  <b><CopyToClipboardButton getText={() => context} />
                    <span onClick={() => setShowFull(!showFull)}>{
                      showFull
                      ? "Full Instructions"
                      : `Formatted Search Results${hasSearchContext ? "" : " (none)"}`
                    }</span>
                  </b>
                  <span title={`${showFull ? "Full instructions" : "Search context"} passed to the LLM`}>
                    {context
                      .split("\n")
                      .map((line, idx) => (<span key={idx}>{line}<br/></span>))}
                  </span>
                </label>)}
            </div>
            <div className="ai-templates" key="ai-templates" >{
              !showTemplates ? (<div onClick={toggleShowTemplates}>{"Prompt Templates"}</div>) : (
                <div>
                  <span onClick={toggleShowTemplates}>Select a Template </span>
                  <select
                    onChange={(e) => {
                      setSelectedTemplate(e.target.value)
                    }}
                    defaultValue={selectedTemplate}
                    title={"Choose a template to edit"}>
                    {Object.keys(templates).map(key => (
                      <option key={key} value={key}>{key}</option>
                    ))}
                  </select>
                  <textarea
                    value={templates[selectedTemplate] || ""}
                    onChange={e => onTemplateChange(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === "Escape") {
                        e.stopPropagation();
                        e.preventDefault();
                        onTemplateChange(aiTemplates[selectedTemplate])
                      }
                    }}
                  />
                </div>)}
            </div>
          </div>)}
      </Modal.Body>
    </Modal>
  )
};

export default inject("userStore", "appStatusStore")(observer(ChatModal));
